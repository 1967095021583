import React from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header, Section } from 'pnk-components';
import DeviceImage from '../../assets/images/example.jpg';

const WebProduct = ({ classes }) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Section id="websites">
            <Grid
                container
                spacing={4}
                justifyContent="space-around"
                alignItems="center"
                direction={sm ? 'column-reverse' : 'row'}
                className={classes.container}
            >
                <Grid item xs={12} sm={6} md={6}>
                    <img src={DeviceImage} alt="Website Design & Development" className={classes.image} />
                </Grid>
                <Grid item xs={12} sm={8} md={5} lg={4}>
                    <Header
                        title="Your business on any device"
                        overline="Websites"
                    />
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        Tired of your old website? Not generating any business from it? Then we can design and build a great looking site that can be viewed on any device.
                    </Typography>
                </Grid>
            </Grid>
        </Section>
    );
};

const styles = theme => ({
    root: {
        //
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    image: {
        width: '100%',
    },
});

WebProduct.defaultProps = {

};

WebProduct.propTypes = {

};

export default withStyles(styles)(WebProduct);
