import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hero from '../../sections/Hero';
import Contact from '../../sections/Contact';
import Services from '../../sections/Services';
import Faqs from '../../sections/Faqs';
import Features from '../../sections/Features';
import Products from '../../sections/Products';
import Why from '../../sections/Why';
import WebProduct from '../../sections/WebProduct';
import AppProduct from '../../sections/AppProduct';

/**
 * The home page
 *
 * Hero
 * Services
 * Why
 * Web Products
 * App Product
 * Faqs
 * Contact
 */
const Home = ({ classes }) => (
    <>
        <Hero />
        <Services />
        <Why />
        <Features />
        <WebProduct />
        <AppProduct />
        <Products />
        <Faqs />
        <Contact />
    </>
);

const styles = theme => ({
    root: {

    },
});

export default withStyles(styles)(Home);
