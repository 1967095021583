import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import MuiContainer from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { ArticleSummary, Header, Container } from 'pnk-components';

const Articles = ({ classes, articles, onArticleSelect }) => (
    <Container className={classes.root} maxWidth={false}>
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Header
                        title="Latest articles"
                        overline="News"
                        align="center"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <Grid
                        container
                        spacing={4}
                        alignItems="stretch"
                    >
                        {articles.map(article => (
                            <Grid
                                key={article.id}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                <ArticleSummary
                                    article={article}
                                    ButtonProps={{
                                        component: RouterLink,
                                        to: `/news/${article.id}`,
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </MuiContainer>
    </Container>
);

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        minHeight: 800,
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(8),
    },
});

Articles.defaultProps = {

};

Articles.propTypes = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            date: PropTypes.string,
            title: PropTypes.string,
            summary: PropTypes.string,
            thumbnail: PropTypes.string,
            author: PropTypes.shape({
                name: PropTypes.string,
                avatar: PropTypes.string,
            }),
        }),
    ).isRequired,
    onArticleSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(Articles);
