import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Article from '../../sections/Article';

const article = {
    id: '1',
    date: '2019-11-15T10:37:59',
    title: 'Shrimp and Chorizo Paella',
    summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
    text: '<p>Nunc fringilla quam at lectus gravida, <strong>nec porttitor turpis mollis</strong>. Sed eget porta urna. Sed finibus, ante non porttitor consectetur, erat ex ultrices diam, sit amet mollis odio nisi ut turpis.</p><p>Ut finibus sem a elit egestas, sit amet eleifend arcu tincidunt. Suspendisse tempus tortor vel nisi tincidunt sagittis. Fusce porttitor enim ut leo lacinia, at pharetra turpis mollis.</p><p>Duis ut metus eget lectus euismod tempor sed id mi. Donec imperdiet orci eget purus sagittis, vitae molestie nisl consectetur. Nunc eget consectetur mauris. Aliquam vulputate semper vulputate. Aliquam egestas quam et mauris molestie pretium.</p>',
    image: 'https://techcrunch.com/wp-content/uploads/2019/08/topfunnel-dover-lawsuit.jpg?w=850&h=492&crop=1',
};

/**
 *
 * Articles
 *
 */
const NewsItem = ({ classes }) => (
    <>
        <Article article={article} onGoBack={() => {}} />
    </>
);

const styles = theme => ({
    root: {

    },
});

NewsItem.defaultProps = {

};

NewsItem.propTypes = {
    article: PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        title: PropTypes.string,
        summary: PropTypes.string,
        thumbnail: PropTypes.string,
        text: PropTypes.string,
        author: PropTypes.shape({
            name: PropTypes.string,
            avatar: PropTypes.string,
        }),
    }).isRequired,
};

export default withStyles(styles)(NewsItem);
