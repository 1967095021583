/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_content_delivery_bucket": "sitecloudpnk-20210713153542-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "http://sitecloudpnk-20210713153542-hostingbucket-dev.s3-website.eu-west-2.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://xwqvvuwlrzacjof6uxcvjt56pi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-b2mva7pt5zgwbelypallykbrpi"
};


export default awsmobile;
