import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header, Section } from 'pnk-components';
import BodyImage from '../../assets/images/thinking.png';

const Why = ({ classes }) => (
    <Section id="why" classes={{ root: classes.root }}>
        <Grid
            container
            spacing={4}
            justifyContent="space-around"
            alignItems="center"
        >
            <Grid item xs={12} sm={6} md={5} lg={4}>
                <Header
                    title="Why do we offer this?"
                    overline="Our mission"
                />
                <Typography
                    variant="body1"
                    gutterBottom
                >
                    In our experience many businesses are supplied needlessly complex website solutions with content management systems, hosting servers and databases.
                </Typography>
                <Typography
                    variant="body1"
                    gutterBottom
                >
                    We like to offer simple efficient online solutions which meet your requirements with less cost and less bloat.
                </Typography>
                <Typography
                    variant="body1"
                    gutterBottom
                >
                    It's our mission to provide a friendly no-nonsense service to help bring businesses online.
                </Typography>
            </Grid>
            <Grid item md={4} lg={3} implementation="css" component={Hidden} />
        </Grid>
    </Section>
);

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 1,
        '&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: 800,
            height: 715,
            backgroundImage: `url(${BodyImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            zIndex: -1,
            [theme.breakpoints.down('md')]: {
                width: 670,
                height: 545,
            },
            [theme.breakpoints.down('sm')]: {
                width: 350,
                height: 410,
            },
            [theme.breakpoints.down('xs')]: {
                width: 220,
                height: 256,
            },
        },
    },
});

Why.defaultProps = {

};

Why.propTypes = {

};

export default withStyles(styles)(Why);
