import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    required,
    validEmail,
} from '../../services/validation';

const inputField = (field) => (
    <TextField
        variant="outlined"
        fullWidth
        {...field.input}
        label={field.label}
        placeholder={field.placeholder}
        type={field.type}
    />
);

const selectField = (field) => (
    <Select
        labelId="subject-label"
        label={field.label}
        {...field.input}
    >
        <MenuItem value="General enquiry">General enquiry</MenuItem>
        <MenuItem value="Callback">Callback</MenuItem>
        <MenuItem value="Pricing">Pricing</MenuItem>
        <MenuItem value="Package">Package</MenuItem>
        <MenuItem value="Help & Support">Help & Support</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
    </Select>
);
const textInputField = (field) => (
    <TextField
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        {...field.input}
        label={field.label}
        placeholder={field.placeholder}
        type={field.type}
    />
);

const getButtonLabel = (status) => {
    if (status === 'OK') {
        return (<>Sent <CheckCircleIcon /></>);
    }
    return 'Send';
};

let ContactForm = ({
    classes,
    resolving,
    handleSubmit,
    onSubmit,
    valid,
    status,
}) => (
    <Card elevation={0} className={classes.root}>
        <CardContent className={classes.content}>
            <Typography variant="h6" color="primary" gutterBottom>
                Get in touch
            </Typography>
            {status === 'ERROR' && <Alert severity="error">Sorry, there was an issue sending your request.</Alert>}
            <form className={classes.root} noValidate autoComplete="off">
                <Field
                    name="name"
                    label="Name"
                    component={inputField}
                    type="text"
                    placeholder="First Name"
                    validate={[required]}
                />
                <Field
                    name="email"
                    label="Email"
                    component={inputField}
                    type="email"
                    placeholder="Email"
                    validate={[required, validEmail]}
                />
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="subject-label">Subject</InputLabel>
                    <Field
                        name="subject"
                        label="Subject"
                        component={selectField}
                        validate={[required]}
                    />
                </FormControl>
                <Field
                    name="message"
                    label="Message"
                    variant="outlined"
                    fullWidth
                    component={textInputField}
                    validate={[required]}
                />
                <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={resolving || !valid}
                    onClick={(onSubmit) => { if (status !== 'OK') { handleSubmit(onSubmit); } }}
                    className={classes.button}
                >{getButtonLabel(status)}</Button>
            </form>
        </CardContent>
    </Card>
);

const styles = theme => ({
    root: {
        '& > div': {
            marginBottom: theme.spacing(2),
        },
    },
    content: {
        padding: theme.spacing(6),
        '& > h6': {
            marginBottom: theme.spacing(4),
        },
        '&:last-child': {
            paddingBottom: theme.spacing(6),
        },
    },
    button: ({ status }) => {
        let style = {};
        if (status === 'OK') {
            style = {
                backgroundColor: theme.palette.success.main,
                '&:hover': {
                    backgroundColor: theme.palette.success.dark,
                },
            };
        };
        return {
            ...style,
            '& svg': {
                marginLeft: theme.spacing(1),
            },
        };
    },
});

ContactForm.defaultProps = {
    resolving: false,
};

ContactForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    resolving: PropTypes.bool,
};

ContactForm = withStyles(styles)(ContactForm);

export default reduxForm({
    form: 'contact' // a unique identifier for this form
})(ContactForm)
