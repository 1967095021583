import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Header, Section } from 'pnk-components';
import DeviceImage from '../../assets/images/phone-app.png';

const AppProduct = ({ classes }) => (
    <Section id="apps" backgroundColor="pink" color="white" classes={{ root: classes.root }}>
        <Grid
            container
            spacing={4}
            justifyContent="space-around"
            alignItems="center"
            className={classes.container}
        >
            <Grid item xs={12} sm={6} md={5} lg={4}>
                <Header
                    title="Does your business need an app?"
                    overline="Apple & Android Apps"
                    color="white"
                />
                <Typography
                    variant="body1"
                    gutterBottom
                    color="white"
                >
                    Do you have a great idea or business that would work great as an application on your phone or tablet? We'd love to help bring your app to life!
                </Typography>
                <Box className={classes.ctaButtons}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        href="https://www.pnk-digital.com"
                    >
                        Find out more
                    </Button>
                </Box>
            </Grid>
            <Grid item md={4} lg={3} implementation="css" component={Hidden} />
        </Grid>
    </Section>
);

const styles = theme => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
        '&:before': {
            content: '""',
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: 500,
            height: 670,
            backgroundImage: `url(${DeviceImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            [theme.breakpoints.down('sm')]: {
                right: -110,
                width: 385,
                height: 489,
            },
            [theme.breakpoints.down('xs')]: {
                right: 0,
                bottom: 0,
                width: '80%',
                height: 290,
            },
            '@media (max-width: 320px)': {
                height: 250,
            },
        },
    },
    container: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    image: {
        width: '100%',
    },
});

AppProduct.defaultProps = {

};

AppProduct.propTypes = {

};

export default withStyles(styles)(AppProduct);
