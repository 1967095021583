import React from 'react';
import Grid from '@material-ui/core/Grid';
import { scroller } from 'react-scroll';
import { Header, Product, Section } from 'pnk-components';

const scrollAttrs = {
    activeClass: 'active',
    spy: true,
    smooth: true,
    offset: 0,
    duration: 500,
};

const products = [
    {
        item: {
            id: '1',
            pricePrefix: 'from',
            price: '£14.99',
            subPriceText: 'per month',
            title: 'Cloud starter',
            subTitle: 'Great looking brochure site',
            quote: 'The perfect package for a small business looking to start but keep costs low.',
            features: [
                {
                    id: '1',
                    name: 'Single page website',
                    isAvailable: true,
                },
                {
                    id: '2',
                    name: 'Built for desktop & mobile',
                    isAvailable: true,
                },
                {
                    id: '3',
                    name: 'Contact form',
                    isAvailable: true,
                },
                {
                    id: '4',
                    name: 'Analytics setup',
                    isAvailable: true,
                },
                {
                    id: '5',
                    name: 'Hosting',
                    isAvailable: true,
                },
            ],
            label: 'Enquire',
        },
        highlight: true,
    },
    {
        item: {
            id: '2',
            pricePrefix: 'from',
            price: '£49.99',
            subPriceText: 'per month',
            title: 'Ecommerce Cloud',
            subTitle: 'Sell your products & services online',
            quote: 'Add more features to your website to show off what you do.',
            features: [
                {
                    id: '1',
                    name: 'Fully bespoke website',
                    isAvailable: true,
                },
                {
                    id: '2',
                    name: 'Built for desktop & mobile',
                    isAvailable: true,
                },
                {
                    id: '3',
                    name: 'Ecommerce / Payment',
                    isAvailable: true,
                },
                {
                    id: '4',
                    name: 'Contact form',
                    isAvailable: true,
                },
                {
                    id: '5',
                    name: 'Gallery',
                    isAvailable: true,
                },
                {
                    id: '6',
                    name: 'Blog / News pages',
                    isAvailable: true,
                },
                {
                    id: '7',
                    name: 'Analytics setup',
                    isAvailable: true,
                },
                {
                    id: '8',
                    name: 'Hosting',
                    isAvailable: true,
                },
            ],
            label: 'Enquire',
        },
        highlight: false,
    },
    {
        item: {
            id: '3',
            pricePrefix: 'from',
            price: '£199.99',
            subPriceText: 'per month',
            title: 'Bespoke Cloud',
            subTitle: 'Advanced build bringing your ideas to life',
            quote: 'If you have a great idea we can built it.',
            features: [
                {
                    id: '1',
                    name: 'Bespoke website or web application',
                    isAvailable: true,
                },
                {
                    id: '2',
                    name: 'Responsive for any mobile device',
                    isAvailable: true,
                },
                {
                    id: '3',
                    name: 'Custom built features',
                    isAvailable: true,
                },
                {
                    id: '4',
                    name: 'User login and accounts',
                    isAvailable: true,
                },
                {
                    id: '5',
                    name: 'Ecommerce / Payment',
                    isAvailable: true,
                },
                {
                    id: '6',
                    name: 'Contact form',
                    isAvailable: true,
                },
                {
                    id: '7',
                    name: 'Gallery',
                    isAvailable: true,
                },
                {
                    id: '8',
                    name: 'Blog / News pages',
                    isAvailable: true,
                },
                {
                    id: '9',
                    name: 'Analytics setup',
                    isAvailable: true,
                },
                {
                    id: '10',
                    name: 'Hosting',
                    isAvailable: true,
                },
            ],
            label: 'Enquire',
        },
        highlight: false,
    }
];

const Products = ({ classes }) => {
    // debugger;
    return (
        <Section backgroundColor="blue" color="white" id="packages">
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
            >
                <Grid item xs={12}>
                    <Header
                        title="Just right for you"
                        overline="Pricing"
                        align="center"
                        color="inherit"
                    />
                </Grid>
                {products.map(({ item, highlight }) => (
                    <Grid item xs={12} sm={6} md={4} key={`product${item.id}`}>
                        <Product product={item} highlight={highlight} buttonLabel="Get in touch" onClick={() => scroller.scrollTo('contact', scrollAttrs)} />
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
};

Products.defaultProps = {

};

Products.propTypes = {

};

export default Products;
