import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Header, Section } from 'pnk-components';
import handleViewport from 'react-in-viewport';
import FeatureCards from '../../components/FeatureCards';
import SecureFolderIcon from '../../assets/images/icons/secure-folder-icon.svg';
import DeviceIcon from '../../assets/images/icons/device-icon.svg';
import NewsIcon from '../../assets/images/icons/news-icon.svg';
import ContactIcon from '../../assets/images/icons/contact-icon.svg';

const cards = [
    {
        id: '1',
        subTitle: 'Using modern design prinicples and constructed for effecient search engine optimisation.',
        title: 'A great looking website',
        // image: DeviceIcon,
    },
    {
        id: '2',
        subTitle: 'Provides a great experience whether your customer is viewing on a mobile, tablet or desktop.',
        title: 'A responsive website',
        // image: NewsIcon,
    },
    {
        id: '3',
        subTitle: 'Inform your customers with important questions and answers.',
        title: 'FAQs',
        // image: SecureFolderIcon,
    },
    {
        id: '4',
        subTitle: 'Get enquiries instantly emailed to your inbox so you can respond immediately.',
        title: 'Contact form',
        // image: ContactIcon,
    },
    {
        id: '5',
        subTitle: 'Show off your work to potetial customers with an easy to use gallery.',
        title: 'Gallery',
        // image: SecureFolderIcon,
    },
    {
        id: '6',
        subTitle: 'Keep customers updated with your latest news and products easily sharable on social media',
        title: 'Blog / News',
        // image: ContactIcon,
    },
];

const ViewportBlock = handleViewport(FeatureCards);

const Features = ({ classes }) => (
    <Section backgroundColor="greyLight" id="features">
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Header
                    title="What do I get?"
                    overline="Features"
                    align="center"
                />
            </Grid>
            <Grid item xs={12}>
                <ViewportBlock cards={cards} />
            </Grid>
        </Grid>
    </Section>
);

Features.defaultProps = {

};

Features.propTypes = {

};

export default Features;
