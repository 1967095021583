import {
    CONTACT_POST_REQUESTED,
    CONTACT_POST_RESOLVED,
    CONTACT_POST_ERROR,
} from './types';
import contactService from '../../services/contact-service';

/**
 * Action helper which resolves the config
 */
const postContactAction = async (values) => async (dispatch, getState) => {

    const { contact } = getState();

    if (contact.get('resolving')) return false;

    dispatch({
        type: CONTACT_POST_REQUESTED,
    });

    try {
        const payload = await contactService.postContact(values);
        // Dispatch successful result
        dispatch({
            type: CONTACT_POST_RESOLVED,
            payload,
        });
    } catch (error) {
        dispatch({
            type: CONTACT_POST_ERROR,
            payload: (error.response && error.response.data ? error.response.data : error),
        });
        throw error;
    }

};

export default postContactAction;
