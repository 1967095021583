import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MuiContainer from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Container, Article } from 'pnk-components';

const Articles = ({ classes, article, onGoBack }) => (
    <Container className={classes.root} maxWidth={false}>
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={12} md={10}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        component={RouterLink}
                        to="/news"
                    >
                        <ArrowBackIcon fontSize="small" /> Back to articles
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <Article article={article} />
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        component={RouterLink}
                        to="/news"
                    >
                        <ArrowBackIcon fontSize="small" /> Back to articles
                    </Button>
                </Grid>
            </Grid>
        </MuiContainer>
    </Container>
);

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
        color: theme.palette.common.white,
        minHeight: 800,
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(8),
    },
});

Articles.defaultProps = {

};

Articles.propTypes = {
    article: PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        title: PropTypes.string,
        summary: PropTypes.string,
        thumbnail: PropTypes.string,
        text: PropTypes.string,
        author: PropTypes.shape({
            name: PropTypes.string,
            avatar: PropTypes.string,
        }),
    }).isRequired,
    onGoBack: PropTypes.func.isRequired,
};

export default withStyles(styles)(Articles);
