import React from 'react';
import { useStore } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Articles from '../../sections/Articles';

/**
 *
 * Articles
 *
 */
const News = ({ classes }) => {
    // const dispatch = useDispatch();
    const store = useStore();
    const history = useHistory();
    return (
        <>
            <Articles articles={store.getState().articles.toJS().items} onArticleSelect={(id) => history.push(`/news/${id}`)} />
        </>
    );
};

const styles = theme => ({
    root: {

    },
});

export default withStyles(styles)(News);
