import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { CSSTransition } from 'react-transition-group';
import { FeatureCard } from 'pnk-components';
import DotsSVG from '../../assets/images/backgrounds/dots.svg';

const FeatureCards = ({ classes, cards, inViewport, forwardedRef }) => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    const getColumns = () => {
        if (xs) {
            return 1;
        }
        if (sm) {
            return 2;
        }
        return 3;
    };

    return (
        <Box className={classes.root} ref={forwardedRef}>
            <GridList cellHeight={368} cols={getColumns()} spacing={32}>
                {cards.map(({ id, image, title, subTitle }, index) => {
                    const isEven = index % 2 === 0;
                    return (
                        <GridListTile
                            key={id}
                        >
                            <CSSTransition
                                in={inViewport}
                                timeout={10 + (index * 100)}
                                classNames={classes.card}
                            >
                                <FeatureCard
                                    image={image}
                                    color={isEven ? 'secondary' : 'primary'}
                                    title={title}
                                    subTitle={subTitle}
                                    classes={{ root: classes.card }}
                                />
                            </CSSTransition>
                        </GridListTile>
                    );
                })}
            </GridList>
        </Box>
    );
};

const styles = theme => ({
    root: {

    },
    gridItem: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -100,
            right: -28,
            width: 176,
            height: 176,
            background: `url(${DotsSVG}) no-repeat`,
            zIndex: -1,
        },
    },
    card: {
        opacity: 0,
        transition: 'opacity 500ms, transform 500ms',
        '&-enter-done': {
            opacity: 1,
        },
    },
});

FeatureCards.defaultProps = {

};

FeatureCards.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        image: PropTypes.node,
        backgroundPosition: PropTypes.string,
        textPosition: PropTypes.oneOf(['top', 'middle', 'bottom']),
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
    })).isRequired,
};

export default withStyles(styles)(FeatureCards);
