import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StorageIcon from '@material-ui/icons/Storage';
import BuildIcon from '@material-ui/icons/Build';
import LayersIcon from '@material-ui/icons/Layers';
import WebIcon from '@material-ui/icons/Web';
import { Header, Section } from 'pnk-components';
import handleViewport from 'react-in-viewport';
import ServiceCards from '../../components/ServiceCards';

const cards = [
    {
        id: '1',
        title: 'No server or database',
        icon: <StorageIcon />,
    },
    {
        id: '2',
        title: 'No unecessary CMS',
        icon: <WebIcon />,
    },
    {
        id: '3',
        title: 'No expensive hosting fees',
        icon: <LayersIcon />,
    },
    {
        id: '4',
        title: 'Professional build quality',
        icon: <BuildIcon />,
    },
];
const ViewportBlock = handleViewport(ServiceCards);

const Services = ({ classes }) => {
    return (
        <Section id="services" classes={{ root: classes.root }}>
            <Grid
                container
                spacing={4}
                justifyContent="space-around"
                alignItems="center"
                className={classes.grid}
            >
                <Grid item xs={12} sm={12} md={6}>
                    <ViewportBlock cards={cards} />
                </Grid>
                <Grid item xs={12} sm={9} md={5} lg={4}>
                    <Header
                        title="Innovative solutions"
                        overline="Services"
                    />
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        We don't believe businesses should be paying large sums of money to setup and maintain overly complex websites.
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        Our aim is to use our experience with the latest cloud technology to reduce the costs of development and hosting then pass on those savings onto our customers.
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                    >
                        Using cloud technology we can remove the use of old style web servers and databases to increase your security and protection of your online assets.
                    </Typography>
                </Grid>
            </Grid>
        </Section>
    );
};

const styles = theme => ({
    root: {
        overflow: 'hidden',
    },
    grid: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        },
    },
});

Services.defaultProps = {

};

Services.propTypes = {

};

export default withStyles(styles)(Services);
