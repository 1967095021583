import * as Immutable from 'immutable';
import {
    APPLICATION_GET_CONFIG_REQUESTED,
    APPLICATION_GET_CONFIG_RESOLVED,
    APPLICATION_GET_CONFIG_ERROR,
} from '../actions/application/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    config: null,
};

const initialState = Immutable.Map(schema);

/**
 * The Application reducer
 * @param {Object} state
 * @param {Object} action
 */
const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
    case APPLICATION_GET_CONFIG_REQUESTED:
        return state.set('resolving', true);

    case APPLICATION_GET_CONFIG_RESOLVED:
        return state
            .set('resolving', false)
            .set('status', 'OK')
            .set('config', action.payload)

    case APPLICATION_GET_CONFIG_ERROR:
        return state
            .set('resolving', false)
            .set('status', 'ERROR')
            .set('config', null);

    default:
        return state;
    }
};

export { initialState };

export default applicationReducer;
