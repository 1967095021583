import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Header, Faqs as FaqsList, Section } from 'pnk-components';
import DotsSVG from '../../assets/images/backgrounds/dots-full.svg';

const faqs = [
    {
        id: '1',
        title: 'I don\'t have a website or a domain name can you help me?',
        description: 'Yes - We can help you purchase and set up your domain name and work with you through the whole process of designing and building your website.',
    },
    {
        id: '2',
        title: 'I already have a website can I switch?',
        description: 'Yes - we can review your current website and work with you to deliver a great looking website that represents you and your business.',
    },
    {
        id: '3',
        title: 'I\'m not techinal how do I set up my website?',
        description: 'We will work with your throughout the whole design and development process to ensure you get the product you deserve.',
    },
    {
        id: '4',
        title: 'Can I setup an online shop on my website?',
        description: 'Yes - we can set up an e-commerce and payment system for you so you can sell and receive payments online.',
    },
    {
        id: '5',
        title: 'I want some more features on my website. What can I do?',
        description: 'We can work with you to develop custom features. We have years of professional experience working for large brands so we can build any bespoke features you request.',
    },
];

const Faqs = ({ classes }) => (
    <Section backgroundColor="white" id="faqs" classes={{ root: classes.root }}>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <Header
                    title="Here to help!"
                    overline="FAQS"
                    align="center"
                />
            </Grid>
            <Grid item xs={12}>
                <Box className={classes.faqsContainer}>
                    <Box className={classes.faqs}>
                        <FaqsList faqs={faqs} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Section>
);

const styles = theme => ({
    root: {
        overflow: 'hidden',
    },
    faqsContainer: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -40,
            right: -65,
            width: 176,
            height: 176,
            background: `url(${DotsSVG}) no-repeat`,
            zIndex: 0,
        },
    },
    faqs: {
        backgroundColor: theme.palette.common.white,
        position: 'relative',
    },
});

Faqs.defaultProps = {

};

Faqs.propTypes = {

};

export default withStyles(styles)(Faqs);
