import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import { CSSTransition } from 'react-transition-group';
import { ServiceCard } from 'pnk-components';
import DotsSVG from '../../assets/images/backgrounds/dots.svg';

const ServiceCards = ({ classes, cards, inViewport, forwardedRef }) => {
    return (
        <Box className={classes.root} ref={forwardedRef}>
            <GridList cellHeight={250} spacing={32} className={classes.gridList}>
                {cards.map(({ id, icon, title }, index) => {
                    const isEven = index % 2 === 0;
                    const isLarge = index % 4 === 0 || index % 4 === 3;
                    const hasBackground = index % 4 >= 2;
                    let marginTop = 0;
                    if ((!isEven && index === 1) || (!isEven && !isLarge && index > 1)) {
                        marginTop = -125;
                    } else if (!isEven && isLarge && index > 1) {
                        marginTop = -220;
                    }
                    return (
                        <GridListTile
                            key={id}
                            style={{ marginTop, height: isLarge ? 345 : 250 }}
                            className={hasBackground ? classes.gridItem : ''}
                        >
                            <CSSTransition
                                in={inViewport}
                                timeout={300 + (index * 100)}
                                classNames={classes.card}
                            >
                                <ServiceCard
                                    icon={icon}
                                    color={isLarge ? 'secondary' : 'primary'}
                                    classes={{ root: classes.card }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {title}
                                    </Typography>
                                </ServiceCard>
                            </CSSTransition>
                        </GridListTile>
                    );
                })}
            </GridList>
        </Box>
    );
};

const styles = theme => ({
    root: {

    },
    gridList: {
        paddingTop: 120,
        overflow: 'visible',
        '& > li': {
            zIndex: 2,
            '& > div': {
                overflow: 'visible',
            },
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            '& > li': {
                width: '100% !important',
                margin: '0 !important',
                height: '250px !important',
            },
        },
    },
    gridItem: {
        position: 'relative',
        zIndex: 1,
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -100,
            right: -28,
            width: 176,
            height: 176,
            background: `url(${DotsSVG}) no-repeat`,
            zIndex: 0,
        },
    },
    card: {
        opacity: 0,
        transform: 'translateX(-100%)',
        transition: 'opacity 500ms, transform 500ms',
        '&-enter-done': {
            opacity: 1,
            transform: 'translateX(0%)',
        },
    },
});

ServiceCards.defaultProps = {

};

ServiceCards.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })).isRequired,
};

export default withStyles(styles)(ServiceCards);
