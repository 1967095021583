import * as Immutable from 'immutable';
import {
    CONTACT_POST_REQUESTED,
    CONTACT_POST_RESOLVED,
    CONTACT_POST_ERROR,
} from '../actions/contact/types';

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    contact: null,
    error: null,
};

const initialState = Immutable.Map(schema);

/**
 * The contact reducer
 * @param {Object} state
 * @param {Object} action
 */
const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_POST_REQUESTED:
            return state.set('resolving', true);

        case CONTACT_POST_RESOLVED:
            return state
                .set('resolving', false)
                .set('status', 'OK')
                .set('error', null)
                .set('contact', action.payload.data)

        case CONTACT_POST_ERROR:
            return state
                .set('resolving', false)
                .set('status', 'ERROR')
                .set('error', action.payload)
                .set('contact', null);

        default:
            return state;
    }
};

export { initialState };

export default contactReducer;
