import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link as ScrollLink } from 'react-scroll';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiContainer from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container, Header } from 'pnk-components';
import HeaderImage from '../../assets/images/jumping-man.png';

const scrollAttrs = {
    activeClass: 'active',
    spy: true,
    smooth: true,
    offset: 0,
    duration: 500,
};

const Hero = ({ classes }) => (
    <Container maxWidth={false} className={classes.root} id="home">
        <MuiContainer>
            <Grid
                container
                spacing={4}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} sm={8} md={7} lg={5}>
                    <Header
                        title="Get more of what matters to you."
                        overline="Work. Smarter."
                        color="inherit"
                    />
                    <Typography
                        variant="h6"
                        color="inherit"
                        gutterBottom
                        className={classes.text}
                    >
                        We specialise in leveraging technology, providing businesses with an affordable way to move online.
                    </Typography>
                    <Box className={classes.ctaButtons}>
                        <Button
                            color="primary"
                            variant="contained"
                            to="services"
                            component={ScrollLink}
                            {...scrollAttrs}
                        >
                            Services
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </MuiContainer>
    </Container>
);

const styles = theme => ({
    root: {
        height: '100vh',
        position: 'relative',
        minHeight: 800,
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.common.white,
        overflow: 'hidden',
        '&:before': {
            content: '""',
            position: 'absolute',
            right: 10,
            width: 900,
            height: 735,
            backgroundImage: `url(${HeaderImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            [theme.breakpoints.down('md')]: {
                right: -190,
            },
            [theme.breakpoints.down('sm')]: {
                right: -190,
                height: 535,
                width: 650,
            },
            [theme.breakpoints.down('xs')]: {
                bottom: 0,
                width: '80%',
                height: 310,
                left: '10%',
            },
            '@media (max-width: 320px)': {
                height: 250,
            },
        },
        '& > div': {
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                marginTop: -175,
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: -190,
            },
            '@media (max-width: 300px)': {
                marginTop: 0,
            },
        },
    },
    text: {
        marginTop: theme.spacing(4),
    },
    ctaButtons: {
        display: 'flex',
        marginTop: theme.spacing(4),
        '& > button': {
            margin: `0 ${theme.spacing(1.5)}px`,
            '&:first-child': {
                marginLeft: 0,
            },
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
});

Hero.defaultProps = {

};

Hero.propTypes = {

};

export default withStyles(styles)(Hero);
