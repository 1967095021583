import * as Immutable from 'immutable';
import {
    ARTICLES_REQUESTED,
    ARTICLES_RESOLVED,
    ARTICLES_ERROR,
} from '../actions/articles/types';

const articles = [
    {
        id: '1',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },
    {
        id: '2',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },
    {
        id: '3',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. if you like.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },
    {
        id: '4',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add along with the mussels, if you like.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },

    {
        id: '5',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },
    {
        id: '6',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },
    {
        id: '7',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. if you like.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },
    {
        id: '8',
        date: '2019-11-15T10:37:59',
        title: 'Shrimp and Chorizo Paella',
        summary: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add along with the mussels, if you like.',
        thumbnail: 'https://material-ui.com/static/images/cards/paella.jpg',
    },

];

const schema = {
    status: 'INIT', // INIT/OK/ERROR
    resolving: false,
    items: articles,
};

const initialState = Immutable.Map(schema);

/**
 * The Articles reducer
 * @param {Object} state
 * @param {Object} action
 */
const articlesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ARTICLES_REQUESTED:
            return state.set('resolving', true);

        case ARTICLES_RESOLVED:
            return state
                .set('resolving', false)
                .set('status', 'OK')
                .set('items', action.payload)

        case ARTICLES_ERROR:
            return state
                .set('resolving', false)
                .set('status', 'ERROR')
                .set('items', []);

        default:
            return state;
    }
};

export { initialState };

export default articlesReducer;
