import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Logo, AppBarWithScroll, LogoDepartment, Footer } from 'pnk-components';
import getContent from '../services/content/getContent';

const scrollAttrs = {
    activeClass: 'active',
    spy: true,
    smooth: true,
    offset: 0,
    duration: 500,
};

const menuItems = [
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'home', 'label']),
        ButtonProps: {
            to: 'home',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'services', 'label']),
        ButtonProps: {
            to: 'services',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'features', 'label']),
        ButtonProps: {
            to: 'features',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'websites', 'label']),
        ButtonProps: {
            to: 'websites',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'apps', 'label']),
        ButtonProps: {
            to: 'apps',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'faqs', 'label']),
        ButtonProps: {
            to: 'faqs',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
    {
        label: getContent(['components', 'AppBar', 'menuItems', 'contact', 'label']),
        ButtonProps: {
            to: 'contact',
            component: ScrollLink,
            ...scrollAttrs,
        },
    },
];

const year = moment().format('YYYY');
const drawerWidth = 240;

const Page = ({ children, classes }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const isHome = useSelector(state => state.router.location.pathname === '/');
    const locationState = useSelector(state => state.router.location.state);

    useLayoutEffect(() => {
        if (menuItems.findIndex(menuItem => menuItem.ButtonProps.to === locationState) !== -1) {
            scroller.scrollTo(locationState, scrollAttrs);
        }
    });

    return (
        <Box className={classes.root}>
            <AppBarWithScroll
                logo={<RouterLink to="/" className={classes.logoLink}><Logo alt={getContent(['global', 'siteName'])} /></RouterLink>}
                menuItems={isHome ? menuItems : menuItems.map(menuItem => {
                    if (menuItem.ButtonProps.spy) return {
                        ...menuItem,
                        ButtonProps: { to: { pathname: `/`, state: menuItem.ButtonProps.to }, component: RouterLink },
                    };
                    return menuItem;
                })}
                onMenuClick={() => setMenuOpen(!menuOpen)}
                collapse={sm}
            />
            {children}
            <Footer
                logo={<LogoDepartment title={getContent(['global', 'department'])} alt={getContent(['global', 'siteName'])} />}
                copyright={`&copy; ${year} ${getContent(['components', 'Footer', 'copyright'])}`}
                contactLink={
                    <Link href={`mailto:${getContent(['global', 'email'])}`} color="inherit" target="_blank" rel="noopener noreferrer">{getContent(['global', 'email'])}</Link>
                }
                privacyLink={
                    <Link component={RouterLink} to="/privacy-policy" color="inherit">{getContent(['components', 'Footer', 'menuItems', 'privacy', 'label'])}</Link>
                }
            />
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={sm && menuOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => setMenuOpen(!menuOpen)}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {menuItems.map(({ label }) => (
                        <ListItem button key={label}>
                            <ListItemText primary={label} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
};

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    logoLink: {
        textDecoration: 'none',
    },
});

Page.defaultProps = {

};

Page.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Page);
